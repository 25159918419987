export const defaultThemeParams = {
  palette: {
    primary: {
      main: "#212529",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
      secondary: "#212529",
    },
    text: {
      primary: "#000000",
      secondary: "#6c757d",
      navActive: "#FFFFFF",
    },
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 100,
    },
  },
};

export const blackOnCreamThemeParams = {
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#f9f7c7",
      secondary: "#f9f7c7",
      paper: "#f9f7c7",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
      navActive: "#000000",
    },
  },
};

export const yellowOnBlackThemeParams = {
  palette: {
    primary: {
      main: "#FFFF00",
    },
    secondary: {
      main: "#FFFF00",
    },
    background: {
      default: "#000000",
      secondary: "#000000",
      paper: "#000000",
    },
    text: {
      primary: "#FFFF00",
      secondary: "#FFFF00",
      navActive: "#FFFF00",
    },
  },
};
